/* eslint-disable react/prop-types */
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Heading1 from '../components/elements/heading1'
import Paragraph from '../components/elements/paragraph'

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="404: Not Found" />
      <div className="pt-8 pb-16">
        <Heading1>Not Found</Heading1>
        <Paragraph className="mt-4">
          You just hit a route that doesn&#39;t exist... the sadness.
        </Paragraph>
      </div>
    </Layout>
  )
}

export default NotFoundPage
